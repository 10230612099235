<template>
  <div class="col m-6">
    <div class="container-fluid">
      <!-- <div class="row row-cols-1 row-cols-md-12 g-4">
        <div class="col mt-2">
          <div class="alert alert-danger" role="alert">Just.崩潰網站服務調整預告 (10/1起)、2024 Just.崩潰 贊助制會員活動 (24冬季) <a href="https://www.facebook.com/just.bk.me/posts/825584466406333" target="_blank">見詳細公告</a></div>
        </div>
      </div> -->
      <div class="row row-cols-1 row-cols-md-12 g-4">
        <div class="col mt-5">
          <div class="alert alert-danger mt-4" role="alert">Just.崩潰網站服務調整預告 (10/1起)、2024 Just.崩潰 贊助制會員活動 (24冬季) <a href="https://www.facebook.com/just.bk.me/posts/825584466406333" target="_blank">見詳細公告</a></div>
        </div>
        <h4>
          <!-- <p class="mt-5">台灣虎航 24夏季第一波開賣各航點價格一覽表<small> (逐步更新，非完整)</small></p> -->
          <p class="mt-0">{{ main_title }}<small> (逐步更新，非完整)</small></p>
        </h4>
        <small class="mt-0">
          <!-- <h2><p>台灣虎航 24夏季第一波開賣各航點價格一覽表</p></h2> -->
          <ul>
            <li>溫馨提醒：如透過行動裝置進行操作，由於本站對於in-app瀏覽器（如Facebook 或 LINE 內建）支援程度不佳，如遇到資料無法呈現，可建議使用 Google Chrome、Safari 等系統原生或第三方瀏覽器</li>
            <!-- <li v-if="help_title !== ''">
              <span class="fs-6 text-danger">{{ help_title }}</span
              >，若沒有可以點擊一旁 <router-link to="/NewUser" class="btn btn-outline-primary"> 註冊 </router-link>
            </li> -->
            <li>若使用行動裝置，建議可切換成橫向檢視來得到較佳瀏覽體驗</li>
            <li>所提供的票價資訊均是建立在「直飛，台灣出發的來回且大人人數是 1 位」的情況</li>
            <li>僅供參考，由於資料本身具有時效性，建議要搭配參考資料取得時間 (UTC+8)</li>
            <li>{{ sub_title }}</li>
          </ul>
        </small>
        <vue-good-table
          :line-numbers="false"
          :search-options="{
            enabled: false,
          }"
          :pagination-options="{
            enabled: true,
            mode: 'pages',
          }"
          :sort-options="{
            enabled: true,
            multipleColumns: true,
            initialSortBy: [
              { field: 'departureDate', type: 'asc' },
              { field: 'fareAmount', type: 'asc' },
            ],
          }"
          :columns="columns"
          :rows="rows"
          styleClass="vgt-table striped" />
      </div>
    </div>
  </div>
</template>

<script>
  import axios from "axios"
  import Cookies from "js-cookie"
  import GlobalVar from "@/mixins/global_var.js"
  export default {
    name: "VueLowFare",
    // components: { Table },
    mounted() {
      // if (Cookies.get("login_token")) {
      const login_token = Cookies.get("login_token")
      this.checkLoginTokeVaild(login_token)

      let redirect_url = "/Login"

      if (this.$route.query.ref) {
        const ref = this.$route.query.ref
        const airline = ref.split("-")[0]
        const tag = ref.split("-")[1]
        redirect_url = `/Login?url=/LowFare?ref=${airline}-${tag}`
      }

      // alert(sessionStorage.getItem("is_email_confirm"))
      if (!sessionStorage.getItem("is_email_confirm")) {
        this.$alert("10/1 起本頁面僅提供通過帳號驗證之會員使用，如尚未完成者還請先完成驗證後再使用", "系統訊息", "error").then(() => {
          window.location.href = `${redirect_url}`
        })
      }

      // if (sessionStorage.getItem("uuid") && sessionStorage.getItem("is_email_confirm") && sessionStorage.getItem("ref") === true) {
      this.columns.splice(4, 0, {
        label: "張數",
        width: "100px",
        field: "fare1Available",
        filterOptions: {
          styleClass: "class1", // class to be added to the parent th element
          enabled: true, // enable filter for this column
          placeholder: "", // placeholder for filter input
          filterValue: "", // initial populated value for this filter
          // filterDropdownItems: ["TPE"], // dropdown (with selected values) instead of text input
          filterFn: this.columnFilterFn, //custom filter function that
          // trigger: "enter", //only trigger on enter not on keyup
        },
      })
      // }
      // }
      let airline = null,
        tag = null

      this.main_title = "樂桃單程未稅低於 TWD 4000 整理"
      this.sub_title = "價格單位: simple peach，單程未稅 TWD"

      if (sessionStorage) {
        sessionStorage.setItem("page", "LowFare")
        sessionStorage.setItem("ref", false)
        sessionStorage.removeItem("airline", airline)
        sessionStorage.removeItem("tag", tag)
      }

      if (Cookies) {
        Cookies.set("page", "LowFare", { secure: true })
        Cookies.set("ref", false, { secure: true })
        Cookies.remove("airline")
        Cookies.remove("tag")
      }

      if (this.$route.query.ref) {
        const ref = this.$route.query.ref
        airline = ref.split("-")[0]
        tag = ref.split("-")[1]

        // if (airline == "IT") {
        //   this.sub_title = "價格單位: tigerlight，單程未稅 TWD"
        // }

        // if (airline == "MM") {
        //   this.sub_title = "價格單位: simple peach，單程未稅 TWD"
        // }

        if (airline === "MM" && tag === "Sale") {
          window.location.href = `/Lowfare`
          // this.main_title = "樂桃航空 9/19 ~ 9/22 台灣出發優惠活動優惠價懶人包"
          // this.sub_title = "價格單位: simple peach，單程未稅 TWD"
        }
        if (airline === "IT" && tag === "KHHOKJ") {
          window.location.href = `/Lowfare`
          // this.main_title = "台灣虎航 高雄＝岡山 新航線開賣活動懶人包"
          // this.sub_title = "價格單位: tigerlight，單程未稅 TWD"
        }
        if (airline === "IT" && tag === "JPY1000") {
          window.location.href = `/Lowfare`
          // this.main_title = "台灣虎航 JPY 1000 懶人包（需要買日本來回）"
          // this.sub_title = "價格單位: tigerlight，單程未稅 JPY"
        }
        if (airline === "IT" && tag === "MOON") {
          window.location.href = `/Lowfare`
          // this.main_title = "台灣虎航 24夏季 9-10月 低價整理"
          // this.sub_title = "價格單位: tigerlight，單程未稅 TWD"
        }
        if (airline === "IT" && tag === "W24P3") {
          window.location.href = `/Lowfare`
          // this.main_title = "台灣虎航 24冬季第三波開賣價格整理 (秋田/高知/普吉島/澳門)"
          // this.sub_title = "價格單位: tigerlight，單程未稅 TWD"
        }
        if (airline === "TR" && tag === "TUESALE") {
          window.location.href = `/Lowfare`
          // this.main_title = "酷航週二酷閃活動"
          // this.sub_title = "價格單位: 經濟艙，單程未稅 TWD"
        }
        if (airline === "IT" && tag === "JPWEEK") {
          window.location.href = `/Lowfare`
          // this.main_title = "台灣虎航平日出發活動懶人包(日本)"
          // this.sub_title = "價格單位: tigerlight，單程未稅 TWD"
        }
        if (airline === "IT" && tag === "KRWEEK") {
          window.location.href = `/Lowfare`
          // this.main_title = "台灣虎航平日出發活動懶人包(韓國)"
          // this.sub_title = "價格單位: tigerlight，單程未稅 TWD"
        }
        if (airline === "IT" && tag === "WEEKDAY") {
          window.location.href = `/Lowfare`
          // this.main_title = "台灣虎航平日出發活動懶人包(泰國、澳門、越南)"
          // this.sub_title = "價格單位: tigerlight，單程未稅 TWD"
        }
        if (airline === "IT" && tag === "FUKPUS") {
          window.location.href = `/Lowfare`
          // this.main_title = "台灣虎航平日出發活動懶人包(日本福岡、韓國釜山)"
          // this.sub_title = "價格單位: tigerlight，單程未稅 TWD"
        }
        if (airline === "AK" && tag === "KHHBKI") {
          window.location.href = `/Lowfare`
          // this.main_title = "AirAsia 高雄＝亞庇開航優惠活動"
          // this.sub_title = "價格單位: 經濟艙，單程未稅 TWD"
        }
        if (airline === "MM" && tag === "W24SALE") {
          // window.location.href = `/Lowfare`
          this.main_title = "樂桃國際線冬季優惠活動 (台灣出發，僅列各航點起始價)"
          this.sub_title = "價格單位: Standard，單程未稅 TWD"
        }
        if (airline === "IT" && tag === "2402CTS") {
          window.location.href = `/Lowfare`
          // this.main_title = "台灣虎航 24冬季 台北＝新千歲 2月增班 2599 整理"
          // this.sub_title = "價格單位: tigerlight，單程未稅 TWD"
        }
        if (airline === "OD" && tag === "LOWSALE") {
          window.location.href = `/Lowfare`
          // this.main_title = "峇迪航空低價&促銷價整理"
          // this.sub_title = "票種及價格單位: 超級節省，單程未稅 TWD"
        }
        if (airline === "IT" && tag === "OKJSALE") {
          window.location.href = `/Lowfare`
          // this.main_title = "台灣虎航 岡山 1899 整理"
          // this.sub_title = "價格單位: tigerlight，單程未稅 TWD"
        }
        if (airline === "IT" && tag === "KMISALE") {
          window.location.href = `/Lowfare`
          // this.main_title = "台灣虎航 台北＝宮崎 開賣 1799 整理"
          // this.sub_title = "價格單位: tigerlight，單程未稅 TWD"
        }

        if (airline === "TEST" && tag === "TT") {
          this.main_title = "測試用"
          this.sub_title = ""

          if (typeof sessionStorage !== "undefined") {
            alert("ok")
          } else {
            alert("not ok")
          }
        }
        this.help_title = "如持有本站會員帳號，可於登入後查看該優惠價格所持有的張數"
        if (sessionStorage) {
          sessionStorage.setItem("ref", true)
          sessionStorage.setItem("airline", airline)
          sessionStorage.setItem("tag", tag)
        }
        if (Cookies) {
          Cookies.set("ref", true, { secure: true })
          Cookies.set("airline", airline, { secure: true })
          Cookies.set("tag", tag, { secure: true })
        }
      }

      this.initLowFare(airline, tag)
    },
    mixins: [GlobalVar],
    data: function () {
      return {
        // user_uuid: null,
        main_title: "",
        sub_title: "",
        help_title: "",
        isLoading: false,
        columns: [
          {
            label: "前往官網",
            width: "150px",
            field: "goLink",
            html: true,
          },
          {
            label: "出發日",
            field: "departureDate",
            type: "date",
            dateInputFormat: "yyyy-MM-dd",
            dateOutputFormat: "yyyy-MM-dd",
            filterOptions: {
              styleClass: "class1", // class to be added to the parent th element
              enabled: true, // enable filter for this column
              placeholder: "", // placeholder for filter input
              filterValue: "", // initial populated value for this filter
              // filterDropdownItems: ["TPE"], // dropdown (with selected values) instead of text input
              // filterFn: this.columnFilterFn, //custom filter function that
              // trigger: "enter", //only trigger on enter not on keyup
            },
          },
          {
            label: "班次",
            field: "flightNo",
            width: "130px",
            filterOptions: {
              styleClass: "class1", // class to be added to the parent th element
              enabled: true, // enable filter for this column
              placeholder: "", // placeholder for filter input
              filterValue: "", // initial populated value for this filter
              // filterDropdownItems: ["TPE"], // dropdown (with selected values) instead of text input
              // filterFn: this.columnFilterFn, //custom filter function that
              // trigger: "enter", //only trigger on enter not on keyup
            },
          },
          // {
          //   label: "currency",
          //   field: "currency",
          // },
          {
            label: "價格",
            width: "130px",
            field: "fareAmount",
            filterOptions: {
              styleClass: "class1", // class to be added to the parent th element
              enabled: true, // enable filter for this column
              placeholder: "", // placeholder for filter input
              filterValue: "", // initial populated value for this filter
              // filterDropdownItems: ["TPE"], // dropdown (with selected values) instead of text input
              filterFn: this.columnFilterFn, //custom filter function that
              // trigger: "enter", //only trigger on enter not on keyup
            },
          },

          {
            label: "出發地",
            width: "150px",
            field: "departureStation",
            filterOptions: {
              styleClass: "class1", // class to be added to the parent th element
              enabled: true, // enable filter for this column
              placeholder: "", // placeholder for filter input
              filterValue: "", // initial populated value for this filter
              // filterDropdownItems: ["TPE"], // dropdown (with selected values) instead of text input
              // filterFn: this.columnFilterFn, //custom filter function that
              // trigger: "enter", //only trigger on enter not on keyup
            },
          },
          {
            label: "目的地",
            width: "150px",
            field: "arrivalStation",
            filterOptions: {
              styleClass: "class1", // class to be added to the parent th element
              enabled: true, // enable filter for this column
              placeholder: "", // placeholder for filter input
              filterValue: "", // initial populated value for this filter
              // filterDropdownItems: ["TPE"], // dropdown (with selected values) instead of text input
              // filterFn: this.columnFilterFn, //custom filter function that
              // trigger: "enter", //only trigger on enter not on keyup
            },
          },
          {
            label: "資料時間",
            width: "150px",
            field: "fetchCreatedAt",
          },
        ],
        rows: [],
      }
    },
    methods: {
      // checkLoginTokeVaild(login_token) {
      //   const me = this
      //   const json_d = { token: login_token }

      //   axios({
      //     method: "POST",
      //     url: `${me.$apiUrl2}checkLoginTokeVaild`,
      //     data: json_d,
      //     headers: {
      //       Authorization: `Bearer 76s6hBdR594llBaEBNtP`,
      //       "Content-Type": "application/json",
      //     },
      //   }).then(function (response) {
      //     const res = response.data

      //     if (res.status === 201) {
      //       if (res.statusText === "Select") {
      //         me.user_uuid = res.data.id
      //         sessionStorage.setItem("uuid", res.data.id)
      //         Cookies.set("uuid", res.data.id, { secure: true })
      //         // if (me.$route.name === "login") {
      //         // window.location.href = `/Subscribe?id=${res.data.id}&op=notify_manage`
      //         // }
      //       }
      //     }
      //     if (res.status === 503) {
      //       me.main_page_switch = false
      //       me.user_uuid = null
      //       // window.location.href = `/Login`
      //     }
      //   })
      // },
      initLowFare(airline, tag) {
        const me = this
        // const apiUrl = "https://justbk-api-4cqv3jfjdq-de.a.run.app/"
        // const apiUrl = "http://localhost:8000/"
        if (airline == null && tag == null) {
          axios.get(`${me.$apiUrl}lowfare`).then((response) => {
            me.rows = response.data
          })
        } else {
          let user_uuid = null

          if (sessionStorage) {
            if (sessionStorage.getItem("uuid")) {
              user_uuid = sessionStorage.getItem("uuid")
            }
          }

          if (Cookies) {
            if (Cookies.get("uuid")) {
              user_uuid = Cookies.get("uuid")
            }
          }

          // if (airline === "TEST" && tag === "TT") {
          // alert(airline)
          // alert(tag)
          // alert(user_uuid)
          //   alert(`${me.$apiUrl}lowfare`)
          // }

          const json_d = {
            airline: airline,
            tag: tag,
            uuid: user_uuid,
          }

          axios({
            method: "POST",
            url: `${me.$apiUrl}lowfare`,
            data: json_d,
            headers: {
              Authorization: `Bearer 76s6hBdR594llBaEBNtP`,
              "Content-Type": "application/json",
            },
          }).then(function (response) {
            // const status = response.status
            // alert(status)
            me.rows = response.data
          })

          // axios
          //   .post(`${me.$apiUrl}lowfare`, {
          //     airline: airline,
          //     tag: tag,
          //     uuid: user_uuid,
          //   })
          //   .then((response) => {
          //     const status = response.status
          //     alert(status)
          //     me.rows = response.data
          //   })
        }
      },
      columnFilterFn: function (data, filterString) {
        // console.log(data)
        var x = parseInt(filterString)
        // console.log(x)
        return data <= x
        // return data >= x - 5 && data <= x + 5
      },
    },
    // filters: {
    //   getWeekDay: function (date) {
    //     const weeks = ["", "一", "二", "三", "四", "五", "六", "日"]
    //     return weeks[moment(date).isoWeekday()]
    //   },
    //   formatDate: function (date) {
    //     return moment(date).format("MM/DD")
    //   },
    // },
  }
</script>

<style></style>
