<template>
  <div class="row">
    <div class="col-12">
      <div class="row row-cols-1 mt-5">
        <main class="mt-3 w-75 m-auto"></main>
      </div>
    </div>
  </div>
</template>
<script>
  import axios from "axios"
  import Cookies from "js-cookie"
  export default {
    data: function () {
      return {}
    },
    mounted() {
      if (Cookies.get("login_token")) {
        const login_token = Cookies.get("login_token")
        this.userLogout(login_token)
      } else {
        window.location.href = `/`
      }
    },
    methods: {
      async userLogout(login_token) {
        try {
          const me = this
          const json_d = { token: login_token }
          axios({
            method: "POST",
            url: `${me.$apiUrl2}userLogout`,
            data: json_d,
            headers: {
              Authorization: `Bearer 76s6hBdR594llBaEBNtP`,
              "Content-Type": "application/json",
            },
          }).then(function () {
            Cookies.remove("login_token")

            Cookies.remove("page")
            Cookies.remove("ref")
            Cookies.remove("is_email_confirm")
            Cookies.remove("is_used")
            Cookies.remove("uuid")

            sessionStorage.removeItem("page")
            sessionStorage.removeItem("ref")
            sessionStorage.removeItem("is_email_confirm")
            sessionStorage.removeItem("is_used")
            sessionStorage.removeItem("uuid")

            window.location.href = `/`
          })
        } catch (err) {
          //   this.alerts.push(err)
        }
      },
    },
  }
</script>
<style lang=""></style>
